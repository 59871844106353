<template>
  <div class="container">
    <div class="row flex-lg-row-reverse">
      <input type="hidden" name="_token" value="5pCHlgI9PoI6hnO74qX2ksU8TGCfEZSl6orDbjLP">
      <div class="col-lg-4 pr-0 user-info-wrapp">
        <!--
        <div class="user-info__notificate">
          Ваш аккаунт на проверке
        </div>
        <div class="user-info__descr">
          <b>Почему ваш аккаунт на проверке?</b>
          <p>Сайт рыбатекст поможет дизайнеру, верстальщику, вебмастеру сгенерировать несколько абзацев более менее осмысленного текста рыбы на русском языке, а начинающему оратору отточить навык публичных выступлений в домашних условиях.</p>
        </div>-->
        <userInfo/>
        <p class="tip">Тут відображається інформація, яку ви можете редагувати зліва</p>
      </div>
      <div class="col-lg-8 user-form pl-0">
        <profileInfo/>
    </div>
  </div>
</div>
</template>

<script>
import userInfo from '@/components/app/profile/userInfo.vue'
import profileInfo from '@/components/app/profile/profileInfo.vue'
  export default {
    components: {userInfo, profileInfo},
  }
</script>
