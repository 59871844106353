export default {
    data() {
        return {
            skeletonOff: true,
            citiesList: [],
        }
    },
    computed: {
        activityTypes() {
            return this.$store.state.activityTypesStore.list;
        },
        user: {
            get() {
                return this.$store.getters.PROFILE_INFO ? this.$store.getters.PROFILE_INFO : null;
            },
            set(value) {
                this.$store.commit('SET_PROFILE_INFO', value)
            }
        },
    },
    watch: {
        'user.typeId': function (value) {
            this.userInfo.type_id = value
            this.$store.commit('SET_USERINFO', this.userInfo)
        }
    },
    methods: {
        fetchCities(query) {
            axios.get('/api/directories/cities/find?city=' + query).then(({data}) => {
                this.citiesList = data.data;
            }).catch(err => (console.log(err)));
        },
        searchCities(query) {
            if (query.length > 1) {
                this.fetchCities(query);
            }
        },
    },
    mounted() {
        let unwatch = this.$watch('user', function (value) {
                if (value) {
                    this.skeletonOff = false;
                    if (unwatch) {
                        unwatch()
                    }
                }
            },
            {
                immediate: true
            }
        )
    }
}
