<template>
    <form @submit.prevent="submitHandler" v-if="onload">
        <personal-company-form v-if="userType.type_id === 1" ref="personalCompanyForm"></personal-company-form>
        <personal-form v-else ref="personalForm"></personal-form>
        <address-company-form v-if="userType.type_id === 1"></address-company-form>
        <address-form v-else></address-form>
        <bank-details v-if="userType.type_id === 1"></bank-details>
        <div v-if="! isLandingUserType()" class="user-section">
            <div class="row">
                <div class="col-12 user-section-header">
                    <h3>Змінити пароль</h3>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <input v-model.trim="$v.password.$model"  type="password" name="password" placeholder="Новий пароль"
                               class="form-control ">

                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <input  v-model.trim="$v.repeatPassword.$model" type="password" name="password_confirmation"
                               placeholder="Повторіть новий пароль" class="form-control ">
                        <small class="error" v-if="!$v.repeatPassword.sameAsPassword">Пароль не збігається</small>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!isPartner && userType && userType.type_id == 1" class="user-section">
            <div class="row">
                <div class="col-12 user-section-header">
                    <h3>Дані компанії</h3>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <input v-if="showUserInfoField()" type="text" v-model="user.companyInfo.name" value=""
                               placeholder="Назва компанії" class="form-control">
                        <PuSkeleton class="skeleton-input" v-else/>

                        <div class="invalid-feedback"></div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <input v-if="showUserInfoField()"
                               v-model="user.companyInfo.erdpyCode"
                               type="text" v-mask="'########'"
                               name="edrpu" placeholder="ЄДРПОУ" class="form-control ">
                        <PuSkeleton class="skeleton-input" v-else/>

                        <div class="invalid-feedback"></div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <multiselect
                            v-if="showUserInfoField()"
                            :class="['form-control', 'choice_city']"
                            v-model="user.companyInfo.city"
                            :options="citiesList"
                            placeholder="Місто реєстрації"
                            @search-change="searchCities"
                            :internal-search="false"
                            :max-height="165"
                            :options-limit="300"
                            :allow-empty="false"
                            label="name_full_name_ua">
                        </multiselect>
                        <PuSkeleton class="skeleton-input" v-else/>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <input v-if="showUserInfoField()" type="text" name="location"
                               v-model="user.companyInfo.address"
                               value=""
                               placeholder="Юр. адреса"
                               class="form-control ">
                        <PuSkeleton class="skeleton-input" v-else/>

                        <div class="invalid-feedback"></div>
                    </div>
                </div>
            </div>
        </div>

<!--        TODO temporarily turn off -->
<!--        <div v-if="true" class="user-section">-->
<!--            <div class="user-section">-->
<!--            <div class="row">-->
<!--                <div class="col-12 user-section-header">-->
<!--                    <h3>Документы</h3>-->
<!--                </div>-->
<!--                <div class="col-lg-12 upload-documents">-->
<!--                    <div v-if="showUserInfoField()" class="row">-->
<!--                        <div class="col-lg-6">-->
<!--                            <div class="upload-document__preview">-->
<!--                                <div class="upload-document__preview__content">-->
<!--                                    <img-inputer-->
<!--                                        :img-src="user.documents.firstPassportPage"-->
<!--                                        v-if="showUserInfoField()"-->
<!--                                        v-model="documents.firstPassportPage"-->
<!--                                        accept="image/*"-->
<!--                                        icon="img"-->
<!--                                        placeholder="Завантажте фото документа"-->
<!--                                        capture-->
<!--                                        no-mask-->
<!--                                        no-hover-effect-->
<!--                                        theme="light"-->
<!--                                        @onChange="onSuccess"-->
<!--                                        id="firstPassportPage"-->
<!--                                        size="large"/>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-lg-6 upload-document__content">-->
<!--                            <p>Паспорт: сторінка 1</p>-->
<!--                            <span>Не більше: 7мб</span>-->
<!--                            <button type="button" @click="clearDocumentImg('firstPassportPage')" class="btn-red mb-2">-->
<!--                                Прибрати фото-->
<!--                            </button>-->
<!--                            <button type="button" @click="triggerDocumentImg('#firstPassportPage')" class="btn-blue">-->
<!--                                Вибрати фото-->
<!--                            </button>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <PuSkeleton class="skeleton-input" v-else/>-->

<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--        </div>-->

        <div class="col-lg-12 alert alert-info" v-if="formStatus == 'success'">
            Ваші дані успішно збережені!
        </div>
        <div class="col-lg-12 alert alert-danger" v-if="formStatus == 'error'">
            При відправці сталася помилка, перевірте ваші дані і повторіть відправку!
        </div>
        <div class="d-lg-flex text-center justify-content-end mb-4 mb-lg-0">
            <a href="#" class="btn btn-outline-blue">Скасувати</a>
            <button type="submit" class="btn btn-blue">Зберегти</button>
        </div>
        <div>
        </div>

    </form>
    <div v-else class="section-preloader">
        <img src="https://i.pinimg.com/originals/ae/51/e1/ae51e1395e87cc72c6021df5445cc5f8.gif">
    </div>


</template>

<script>
import PictureInput from 'vue-picture-input'
import Multiselect from 'vue-multiselect';
import { userMixin } from '@/mixin'
import {email, maxLength, minLength, numeric, required, sameAs} from 'vuelidate/lib/validators';
import {mapActions} from "vuex";
import PersonalForm from "@/components/app/profile/profileInfoForms/PersonalForm.vue";
import PersonalCompanyForm from "@/components/app/profile/profileInfoForms/PersonalCompanyForm.vue";
import AddressForm from "@/components/app/profile/profileInfoForms/AddressForm.vue";
import AddressCompanyForm from "@/components/app/profile/profileInfoForms/AddressCompanyForm.vue";
import BankDetails from "@/components/app/profile/profileInfoForms/BankDetails.vue";

export default {
    name: 'profileInfo',
    mixins: [userMixin],
    components: {
        BankDetails,
        AddressCompanyForm, AddressForm, PersonalCompanyForm, PersonalForm, Multiselect, PictureInput},
    data: () => ({
        skeletonOff: false,
        password: '',
        repeatPassword: '',
        file: '',
        formStatus: '',
        citiesList: [],
        onload: true,
        documents: {
            firstPassportPage: '',
        }
    }),

    validations: {
        password: {
            minLength: minLength(6)
        },
        repeatPassword: {
            sameAsPassword: sameAs('password')
        },
    },

    methods: {
        ...mapActions([
            'getActivityTypes'
        ]),
        clearDocumentImg(document) {
            this.user.documents[document] = null;
            this.documents[document] = '';
        },
        showUserInfoField() {
            return this.skeletonOff;
        },
        fetchCities(query) {
            axios.get('/api/directories/cities/find?city=' + query).then(({data}) => {
                this.citiesList = data.data;
            }).catch(err => (console.log(err)));
        },
        searchCities(query) {
            if (query.length > 1) {
                this.fetchCities(query);
            }
        },
        triggerDocumentImg(id) {
            $(id).click();
        },
        onSuccess(res, file) {
            // console.log(file);
        },
        submitHandler() {
            // if (this.$v.$invalid) {
            //     this.$v.$touch()
            //
            //     return
            // }

            console.log(this.isLegalType())
            if (this.isLegalType() && this.$refs.personalCompanyForm.$v.$invalid) {
                this.$refs.personalCompanyForm.$v.$touch()

                return
            } else if(!this.isLegalType() && this.$refs.personalForm.$v.$invalid) {
                this.$refs.personalForm.$v.$touch()

                return
            }

            let data = new FormData();

            data.append("documents[firstPassportPage]", this.documents.firstPassportPage);
            data.append("typeId", this.user.typeId);
            data.append("email", this.user.email);
            data.append("name", this.user.name);
            data.append("password", this.password);
            data.append("surname", this.user.surname || '');
            data.append("patronymic", this.user.patronymic || '');
            data.append("uin", this.user.uin || '');
            data.append("cityId", this.user.city?.id || '');
            data.append("street", this.user.street || '');
            data.append("house", this.user.house || '');
            data.append("apartment", this.user.apartment || '');
            data.append("birthdayAt", this.user.birthdayAt || '');
            data.append("phone", this.user.phone || '');
            data.append("activityTypeId", this.user.activityTypeId || '');
            data.append("companyInfo[name]", this.user.companyInfo.name || '');
            data.append("companyInfo[cityId]", this.user.companyInfo.city?.id || '');
            data.append("companyInfo[street]", this.user.companyInfo.street || '');
            data.append("companyInfo[house]", this.user.companyInfo.house || '');
            data.append("companyInfo[office]", this.user.companyInfo.office || '');
            data.append("companyInfo[erdpyCode]", this.user.companyInfo.erdpyCode || '');
            data.append("companyInfo[bankName]", this.user.companyInfo.bankName || '');
            data.append("companyInfo[bankMfo]", this.user.companyInfo.bankMfo || '');
            data.append("companyInfo[bankIban]", this.user.companyInfo.bankIban || '');

            this.$store.dispatch('updateProfileInfo', data).then(() => (
                    this.$store.dispatch('getProfileInfo'),
                    this.$store.dispatch('GET_USERINFO'),
                    this.onload = true, this.formStatus = 'success'
                )
            )
            .catch(err => (console.log(err), this.onload = true, this.formStatus = 'error'))
        }
    },

    mounted() {
        this.getActivityTypes();

        this.$store.dispatch('getProfileInfo')
            .then((result) => {
                // setTimeout(() => {
                this.user = result.data?.data;
                this.skeletonOff = true;
                // },3000);
            });
    },
    computed: {
        userType: function () {
            return this.$store.getters.USER_INFO
        },
        activityTypes() {
            return this.$store.state.activityTypesStore.list;
        },
        user: {
            get() {
                return this.$store.getters.PROFILE_INFO ? this.$store.getters.PROFILE_INFO : null;
            },
            set(value) {
                this.$store.commit('SET_PROFILE_INFO', value)
            }
        }
    },

}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
