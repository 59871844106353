<script>
import Multiselect from "vue-multiselect";
import {userMixin} from "@/mixin";
import profileForm from "@/components/app/profile/profileInfoForms/mixin/profileForm";

export default {
    name: "BankDetails",
    components: {Multiselect},
    mixins: [userMixin, profileForm],
}
</script>

<template>
    <div class="user-section">
        <div class="row">
            <div class="col-12 user-section-header">
                <h3>Банківські реквізити</h3>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <input v-if="!skeletonOff"
                           v-model.trim="user.companyInfo.bankName"
                           type="text"
                           placeholder="Найменування банку"
                           class="form-control"
                    >
                    <PuSkeleton class="skeleton-input" v-else/>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <input v-if="!skeletonOff"
                           v-model.trim="user.companyInfo.bankMfo"
                           type="text"
                           placeholder="МФО банку"
                           class="form-control"
                    >
                    <PuSkeleton class="skeleton-input" v-else/>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <input v-if="!skeletonOff"
                           v-model.trim="user.companyInfo.bankIban"
                           type="text"
                           placeholder="IBAN"
                           class="form-control"
                    >
                    <PuSkeleton class="skeleton-input" v-else/>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
