<script>
import {userMixin} from "@/mixin";
import {email, maxLength, minLength, numeric, required, sameAs} from "vuelidate/lib/validators";
import profileForm from "@/components/app/profile/profileInfoForms/mixin/profileForm";

export default {
    name: "PersonalForm",
    mixins: [userMixin, profileForm],
    validations: {
        user: {
            email: {email, required},
            name: {required, minLength: minLength(2)},
            surname: {required, minLength: minLength(2)},
            patronymic: {required, minLength: minLength(2)},
            phone: {required, minLength: minLength(19), maxLength: maxLength(19)},
            uin: {required, minLength: minLength(10), maxLength: maxLength(10), numeric},
            birthDate: {minLength: minLength(10), maxLength: minLength(10),},
            // city: {minLength: minLength(2)},
            // street: {minLength: minLength(2)},
            // house: {minLength: minLength(1)},
        }
    },
}
</script>

<template>
    <div class="user-section">
        <div class="row">
            <div class="col-12 user-section-header">
                <h3>Особисті дані</h3>
                <p>Внутрішній ID: {{ getParentId()  || getUserId() }}</p>
            </div>
            <div class="col-lg-4">
                <div class="form-group">
                    <input v-if="!skeletonOff" v-model.trim="user.name" type="text" placeholder="Ім'я"
                           :class="['form-control', {invalid: ($v.user.name.$error)}]">
                    <PuSkeleton class="skeleton-input" v-else/>
                    <small class="error" v-if="$v.user.name.$dirty && !$v.user.name.minLength">Введіть коректне ім'я</small>
                    <small class="error" v-if="$v.user.name.$dirty && !$v.user.name.required">Ім'я обов'язково для заповнення</small>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="form-group">
                    <input v-if="!skeletonOff" v-model.trim="user.patronymic" type="text"
                           placeholder="По батькові*" class="form-control"
                           :class="['form-control', {invalid: ($v.user.patronymic.$error)}]">
                    <PuSkeleton class="skeleton-input" v-else/>

                    <small class="error" v-if="$v.user.patronymic.$dirty && !$v.user.patronymic.minLength">Введіть коректне по батькові</small>
                    <small class="error" v-if="$v.user.patronymic.$dirty && !$v.user.patronymic.required">По батькові обов'язково для заповнення</small>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="form-group">
                    <input v-if="!skeletonOff" v-model.trim="user.surname" type="text" placeholder="Прізвище*"
                           :class="['form-control', {invalid: ($v.user.surname.$error)}]">
                    <PuSkeleton class="skeleton-input" v-else/>
                    <small class="error" v-if="!$v.user.surname.minLength">
                        Введіть коректне прізвище</small>
                    <small class="error" v-if="$v.user.surname.$dirty && !$v.user.surname.required">Прізвище обов'язково для заповнення</small>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <input v-if="!skeletonOff" v-mask="'##########'" v-model.trim="user.uin" type="text"
                           placeholder="ІПН*" :class="['form-control', {invalid: ($v.user.uin.$error)}]">
                    <PuSkeleton class="skeleton-input" v-else/>

                    <small class="error" v-if="$v.user.uin.$dirty && !$v.user.uin.numeric">ІПН повинен містити тільки цифри</small>
                    <small class="error" v-else-if="$v.user.uin.$dirty && !$v.user.uin.minLength">ІПН повинен містити мінімум 10
                    </small>
                    <small class="error" v-else-if="$v.user.uin.$dirty && !$v.user.uin.maxLength">
                        ІПН повинен містити максимум 10</small>
                    <small class="error" v-if="$v.user.uin.$dirty && !$v.user.uin.required">ІПН обов'язково для заповнення</small>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <input v-if="!skeletonOff" v-mask="'##.##.####'" v-model.trim="user.birthdayAt"
                           placeholder="Дата народження"
                           :class="['form-control', 'form-input_date', {invalid: ($v.user.birthDate.$error)}]">
                    <PuSkeleton class="skeleton-input" v-else/>

                    <small class="error" v-if="!$v.user.birthDate.minLength">Введіть дату в форматі
                        12.12.2012</small>
                    <small class="error" v-else-if="!$v.user.birthDate.maxLength">Введіть дату в форматі
                        12.12.2012</small>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <input v-if="!skeletonOff" v-mask="'+38 (###) ###-##-##'" v-model.trim="user.phone"
                           type="text" placeholder="Номер телефону*"
                           :class="['form-control', {invalid: ($v.user.phone.$error)}]">
                    <PuSkeleton class="skeleton-input" v-else/>

                    <small class="error" v-if="!$v.user.phone.minLength">Введіть номер в форматі 380xxxxxxxx</small>
                    <small class="error" v-else-if="!$v.user.phone.maxLength">Введіть номер в форматі
                    </small>
                    <small class="error" v-if="$v.user.phone.$dirty && !$v.user.phone.required">Номер телефону обов'язково для заповнення</small>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <input v-if="!skeletonOff" autocomplete="off" v-model.trim="user.email" type="text" placeholder="Email*"
                           :class="['form-control', {invalid: ($v.user.email.$error)}]" />
                    <PuSkeleton class="skeleton-input" v-else/>
                    <small class="error" v-if="$v.user.email.$dirty && !$v.user.email.required">Введіть свій email</small>
                    <small class="error" v-else-if="$v.user.email.$dirty && !$v.user.email.email">Введіть коректний email</small>
                </div>
            </div>
            <div class="col-lg-6">
                <el-select v-if="!skeletonOff" v-model="user.typeId" placeholder="Тип аккаунта" class="w-100">
                    <el-option
                        v-for="item in userTypes.filter(item => item.id !== 2)"
                        :key="item.id"
                        :label="item.value"
                        :value="item.id">
                    </el-option>
                </el-select>
                <PuSkeleton class="skeleton-input" v-else/>
            </div>
            <div class="col-lg-6">
                <el-select v-if="!skeletonOff" v-model="user.activityTypeId" placeholder="Вид діяльності" class="w-100">
                    <el-option
                        v-for="item in activityTypes"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
                <PuSkeleton class="skeleton-input" v-else/>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
