<script>
import {userMixin} from "@/mixin";
import {email, maxLength, minLength, numeric, required, sameAs} from "vuelidate/lib/validators";
import profileForm from "@/components/app/profile/profileInfoForms/mixin/profileForm";

export default {
    name: "PersonalCompanyForm",
    mixins: [userMixin, profileForm],
    validations: {
        user: {
            email: {email, required},
            companyInfo: {
                name: {required, minLength: minLength(2)},
            },
            name: {required, minLength: minLength(2)},
            // surname: {minLength: minLength(2)},
            // patronymic: {minLength: minLength(2)},
            phone: {minLength: minLength(19), maxLength: maxLength(19)},
            // uin: {minLength: minLength(10), maxLength: maxLength(10), numeric},
            // birthDate: {minLength: minLength(10), maxLength: minLength(10),},
            // city: {minLength: minLength(2)},
            // street: {minLength: minLength(2)},
            // house: {minLength: minLength(1)},
        }
    },
}
</script>

<template>
    <div class="user-section">
        <div class="row">
            <div class="col-12 user-section-header">
                <h3>Особисті дані</h3>
                <p>Внутрішній ID: {{ getParentId()  || getUserId() }}</p>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <input v-if="!skeletonOff" v-model.trim="user.companyInfo.name" type="text" placeholder="Назва компанії*"
                           :class="['form-control', {invalid: ($v.user.companyInfo.name.$error)}]">
                    <PuSkeleton class="skeleton-input" v-else/>
                    <small class="error" v-if="$v.user.companyInfo.name.$dirty && !$v.user.companyInfo.name.minLength">Введіть назву компанії</small>
                    <small class="error" v-if="$v.user.companyInfo.name.$dirty && !$v.user.companyInfo.name.required">Назва компанії обов'язково для заповнення</small>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <input v-if="!skeletonOff" v-model.trim="user.name" type="text"
                           placeholder="Імʼя контактної особи*" class="form-control"
                           :class="['form-control', {invalid: ($v.user.name.$error)}]">
                    <PuSkeleton class="skeleton-input" v-else/>
                    <small class="error" v-if="$v.user.name.$dirty && !$v.user.name.minLength">Введіть імʼя контактної особи</small>
                    <small class="error" v-if="$v.user.name.$dirty && !$v.user.name.required">Введіть імʼя контактної особи</small>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <input v-if="!skeletonOff"
                           v-mask="'+38 (###) ###-##-##'"
                           v-model.trim="user.phone"
                           type="text"
                           placeholder="Номер телефону*"
                           :class="['form-control', {invalid: ($v.user.phone.$error)}]">
                    <PuSkeleton class="skeleton-input" v-else/>

                    <small class="error" v-if="!$v.user.phone.minLength">Введіть номер в форматі 380xxxxxxxx</small>
                    <small class="error" v-else-if="!$v.user.phone.maxLength">Введіть номер в форматі
                    </small>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <input v-if="!skeletonOff" autocomplete="off" v-model.trim="user.email" type="text" placeholder="Електронна адреса*"
                           :class="['form-control', {invalid: ($v.user.email.$error)}]" />
                    <PuSkeleton class="skeleton-input" v-else/>
                    <small class="error" v-if="$v.user.email.$dirty && !$v.user.email.required">Введіть свій email</small>
                    <small class="error" v-else-if="$v.user.email.$dirty && !$v.user.email.email">Введіть коректний email</small>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <input v-if="!skeletonOff" v-mask="'##########'" v-model.trim="user.companyInfo.erdpyCode" type="text"
                           placeholder="ЄДРПОУ" class="form-control">
                    <PuSkeleton class="skeleton-input" v-else/>
<!--                    :class="['form-control', {invalid: ($v.user.erdpyCode.$error)}]"-->
<!--                    <small class="error" v-if="!$v.user.erdpyCode.numeric">ЄДРПОУ повинен містити тільки цифри</small>-->
<!--                    <small class="error" v-else-if="!$v.user.companyInfo.erdpyCode.minLength">ЄДРПОУ повинен містити мінімум 10-->
<!--                    </small>-->
<!--                    <small class="error" v-else-if="!$v.user.companyInfo.erdpyCode.maxLength">-->
<!--                        ЄДРПОУ повинен містити максимум 10</small>-->
                </div>
            </div>

            <div class="col-lg-6">
                <el-select v-if="!skeletonOff" v-model="user.activityTypeId" placeholder="Вид діяльності" class="w-100">
                    <el-option
                        v-for="item in activityTypes"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
                <PuSkeleton class="skeleton-input" v-else/>
            </div>

            <div class="col-lg-6">
                <el-select v-if="!skeletonOff" v-model="user.typeId" placeholder="Тип аккаунта" class="w-100">
                    <el-option
                        v-for="item in userTypes.filter(item => item.id !== 2)"
                        :key="item.id"
                        :label="item.value"
                        :value="item.id">
                    </el-option>
                </el-select>
                <PuSkeleton class="skeleton-input" v-else/>
            </div>

        </div>
    </div>
</template>

<style scoped>

</style>
