<template>
    <div class="user-info" v-if="userInfo">
        <h2>Інформація</h2>
        <h5>Особисті дані</h5>
        <p>{{userInfo.userInfo.surname}} {{userInfo.userInfo.name}} {{userInfo.userInfo.patronymic}}</p>
        <p>{{userInfo.email}}</p>
    </div>
</template>

<script>
export default {
    created() {
        this.$store.dispatch('GET_USERINFO')
    },

    computed : {
        userInfo() {
            return this.$store.getters.USER_INFO;
        },
    },

}
</script>
