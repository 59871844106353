<script>
import Multiselect from "vue-multiselect";
import profileForm from "@/components/app/profile/profileInfoForms/mixin/profileForm";
import {minLength, sameAs} from "vuelidate/lib/validators";
import {userMixin} from "@/mixin";

export default {
    name: "AddressCompanyForm",
    components: {Multiselect},
    mixins: [userMixin, profileForm],
    validations: {
        user: {
            city: {minLength: minLength(2)},
            street: {minLength: minLength(2)},
            house: {minLength: minLength(1)},
        }
    },
}
</script>

<template>
    <div class="user-section">
        <div class="row">
            <div class="col-12 user-section-header">
                <h3>Адреса реєстрації підприємства</h3>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <multiselect
                        v-if="!skeletonOff"
                        :class="['form-control', 'choice_city']"
                        v-model="user.companyInfo.city"
                        :options="citiesList"
                        placeholder="Місто"
                        @search-change="searchCities"
                        :internal-search="false"
                        label="name_full_name_ua"
                        :allow-empty="false"
                        track-by="name">
                        <template v-slot:noOptions>
                            Почніть вводити назву міста
                        </template>
                        <template slot="noResult">
                            Результатів немає
                        </template>
                    </multiselect>
                    <PuSkeleton class="skeleton-input" v-else/>

                    <small class="error" v-if="$v.user.city.$dirty && !$v.user.city.minLength">Введіть коректний місто</small>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <input v-if="!skeletonOff" v-model.trim="user.companyInfo.street" type="text" placeholder="Вулиця"
                           :class="['form-control', {invalid: ($v.user.street.$error)}]">
                    <PuSkeleton class="skeleton-input" v-else/>

                    <small class="error" v-if="$v.user.street.$dirty && !$v.user.street.minLength">Введіть коректну вулицю</small>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <input v-if="!skeletonOff" v-model.trim="user.companyInfo.house" type="text" placeholder="Будинок"
                           :class="['form-control', {invalid: ($v.user.house.$error)}]">
                    <PuSkeleton class="skeleton-input" v-else/>

                    <small class="error" v-if="$v.user.house.$dirty && !$v.user.house.minLength">Введіть коректну адресу</small>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <input v-if="!skeletonOff" v-model.trim="user.companyInfo.office" type="text"
                           placeholder="Офіс" :class="['form-control']">
                    <PuSkeleton class="skeleton-input" v-else/>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
